/* stylelint-disable max-nesting-depth */
@mixin text-clamp($line-clamp-count) {
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  -webkit-line-clamp: $line-clamp-count;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@keyframes mol-live-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

body:has([data-mol-fe-xpmodule-top-stories]):has([data-dark-mode='true']) {
  background-color: #232323;
}

[data-mol-fe-xpmodule-top-stories] {
  font-family: graphik, Arial, sans-serif;
  margin: 0;
  margin-bottom: 20px;
  min-height: 365px;
  padding: 0;

  .isPaywallIneligible :global(.is-paywalled) {
    display: none;
  }

  :global(.is-paywalled) {
    background-size: contain;
    height: 18px;
  }

  &[data-platform='cc'] {
    :global(.is-paywalled) {
      display: inline-flex !important;
    }
  }

  .large,
  .extra-large {
    .grouped-articles {
      padding-bottom: 10px;

      &.doubleColumn {
        max-width: 50%;
      }

      &:first-of-type {
        padding-bottom: $global-padding;
        padding-left: $global-padding;
      }
    }

    .preview-renderer-wrapper {
      display: flex;

      /* 2x6 layout */
      &[data-no-hero-thumbnails='2'] {
        .grouped-articles {
          display: flex;
          flex-direction: column;
          gap: 2px;
          padding-bottom: 10px;
        }

        .article.article-first-child {
          height: 100%;
          max-height: 286px;
        }
      }
    }
  }

  .top-stories {
    width: 100%;

    .header {
      align-items: center;
      display: flex;
      height: auto;
      justify-content: flex-end;
      overflow: hidden;
      position: relative;

      &-text {
        align-items: center;
        display: flex;
        font-size: 15.5px;
        font-weight: 600;
        left: $global-padding;
        position: absolute;
        text-transform: uppercase;

        &-img {
          height: 30px;
          margin-left: 10px;
          width: 19px;
        }
      }
    }

    .divider {
      display: none;
    }

    .articles-container {
      height: auto;
      position: relative;
    }

    .article-badge-container {
      align-items: center;
      display: inline-flex;
      vertical-align: middle;

      > :last-child {
        margin-right: 5px !important;
      }

      > :global(.is-paywalled:not([style*='display: none;'])) + .article-type-sponsored {
        padding: 0 3px;
      }

      > :global(.is-paywalled-sponsored) {
        border-bottom-right-radius: 0.2em;
        border-top-right-radius: 0.2em;
      }
    }

    .article-type-img {
      display: inline;
      width: auto;
    }

    .article {
      img {
        object-fit: cover;
      }

      &-top-container {
        padding: $global-padding;
      }

      &-type {
        position: relative;
        text-transform: uppercase;

        &-live {
          align-items: center;
          background: $amaranth;
          color: $white;
          display: inline-flex;
          font-size: 11px;
          font-weight: 600;
          height: 16px;
          justify-content: space-between;
          line-height: 1; /* center live text */
          position: relative;
          text-align: right;
          width: 44px;

          &-wrapper {
            align-items: center;
            display: inline-flex;
            height: fit-content;
            justify-content: space-between;
            margin: 0 3px;
            width: 100%;
          }

          &-icon {
            background: radial-gradient(circle, transparent 53%, rgba(187, 25, 25, 0.5) 53.5%, #fff 60%);
            border-radius: 50%;
            display: flex;
            height: 9px;
            position: relative;
            width: 9px;

            &::before {
              -webkit-animation: mol-live-pulse 1.7s ease infinite;
              animation: mol-live-pulse 1.7s ease infinite;
              background: radial-gradient(circle, #fff 37%, rgba(187, 25, 25, 0.5) 37.5%, transparent 38%);
              border-radius: 50%;
              content: '';
              height: 9px;
              left: 0;
              position: absolute;
              right: 0;
              width: 9px;
            }
          }
        }

        &-badge {
          align-items: center;
          background: $amaranth;
          color: $white;
          display: inline-flex;
          font-size: 11px;
          font-weight: 600;
          height: 16px;
          line-height: 1; /* center badge text */
          padding: 0 3px;
        }

        &-sponsored {
          color: $cyan;
          font-size: 14px;
        }
      }

      &-headline {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3;
        margin-block-start: auto;
        margin-bottom: 0;

        &-clamp-3 {
          @include text-clamp(3);
        }

        &-clamp-4 {
          @include text-clamp(4);
        }

        &-clamp-5 {
          @include text-clamp(5);
        }

        img {
          margin-right: 5px;
          position: relative;
          top: 2px;
        }

        .article-type-badge {
          display: inline-flex;
          font-size: 13px;
          height: 18px;
        }

        .article-type-live {
          font-size: 13px;
          height: 18px;
          width: 48px;
        }

        .article-type-sponsored {
          font-size: 16px;
        }

        :global(.is-paywalled) {
          height: 18px;
          top: 0;
        }
      }

      &-subheading {
        @include text-clamp(2);

        color: $black;
        font-size: 14px;
        line-height: 1.3;
        margin-block-start: auto;
        margin-bottom: 0;
      }

      &-child {
        display: flex;
        margin: 0 $global-padding;
        padding: $global-padding 0;

        &-text {
          @include text-clamp(4);

          font-stretch: normal;
          font-style: normal;
          font-weight: 600;
          height: fit-content;
          letter-spacing: normal;
          line-height: 1.15;
          margin: 0;
          padding-left: 7px;

          img {
            margin-right: 5px;
            position: relative;
            top: 2px;
          }

          :global(.is-paywalled) {
            height: 16px;
            top: 0;
          }
        }

        &-img {
          height: 85px;
          object-fit: cover;
          width: 116px;
        }
      }
    }

    .view-more {
      align-items: center;
      bottom: 0;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      height: 35px;
      justify-content: center;
      text-decoration: none;
      width: 100%;
    }

    .extra-small {
      min-width: 300px;

      .header-img {
        height: 52px;
      }

      .article {
        &-first-child {
          img {
            display: block;
            height: 186px;
            margin: 0 auto;
            width: 307px;
          }
        }

        &-child {
          img {
            height: 82px;
            width: 112px;
          }

          &-text {
            font-size: 14px;
          }
        }

        &-headline {
          font-size: 17px;
          line-height: 1.21;
        }

        &-subheading {
          font-size: 14px;
          margin-top: 5px;
        }

        &-top-container {
          padding: 8px $global-padding;
        }
      }
    }

    .small {
      max-width: $medium-width-container;
      width: 100%;

      /* stylelint-disable-next-line no-descending-specificity */
      img {
        max-width: 100%;
      }

      &.sportAU {
        .header-text {
          width: 100%;
        }
      }

      .header {
        height: 63px;

        &-text {
          font-size: 19px;
          width: 150px;

          &-img {
            height: 45px;
          }
        }

        &-img {
          height: 63px;
          width: 375px;
        }
      }

      &.side-rail {
        width: 300px;
      }

      .article {
        &-first-child {
          img {
            width: 100%;
          }
        }

        &-child {
          border-top: 1px solid $grey;
          padding: 15px 0;

          &-img {
            height: 100px;
            width: 136px;
          }

          &-text {
            font-size: 17px;
          }
        }

        &-headline {
          font-size: 20px;
          line-height: 1.25;
        }

        &-subheading {
          font-size: 17px;
        }
      }

      .view-more {
        font-size: 16.3px;
        height: 44px;
      }
    }

    .large {
      max-width: $large-width-container;

      [data-no-hero-thumbnails='2'] {
        .article.article-first-child {
          max-height: 277px;
        }
      }

      .header {
        height: 59px;

        &-img {
          height: 59px;
        }

        &-text {
          font-size: 22.4px;
        }
      }

      .article {
        &-first-child {
          padding: 10px 20px 10px 10px;

          img {
            height: 171px;
            width: 290px;
          }
        }

        &-subheading {
          @include text-clamp(1);
        }

        &-top-container {
          padding: 4px 0;
          top: 0;
          width: 250px;
        }

        &-child {
          margin-left: 0;
          padding: $global-padding 0 0 0;

          &-text {
            font-size: 19px;
          }
        }
      }

      .articles-container {
        display: flex;
      }
    }

    .extra-large {
      max-width: $extra-large-width-container;

      .header {
        height: 60px;

        &-text {
          font-size: 25.7px;
        }

        &-img {
          height: 60px;
        }
      }

      .article {
        &-first-child {
          padding: 5px 20px 10px 10px;

          &:first-of-type {
            padding: 10px 20px 0 10px;
          }

          img {
            height: 171px;
            width: 290px;
          }
        }

        &-subheading {
          @include text-clamp(1);
        }

        &-headline {
          font-size: 17px;
        }

        &-top-container {
          padding: 4px 0;
          width: 250px;
        }

        &-child {
          margin-left: 0;
          padding: $global-padding 0 0 0;

          &-text {
            font-size: 16px;
          }
        }
      }

      .articles-container {
        display: flex;
      }

      .grouped-articles {
        max-width: 337px;
        padding-bottom: 10px;
        width: 100%;
      }
    }

    .medium {
      min-width: $small-width-container;

      &.sportAU {
        .header-text {
          width: 100%;
        }
      }

      .header {
        height: 75px;

        &-text {
          font-size: 19.6px;
          left: 17px;
          width: 164px;

          &-img {
            height: 41px;
            width: 23px;
          }
        }

        &-img {
          height: 75px;
        }
      }

      .article {
        &-headline {
          height: 80px;
        }

        &-child {
          margin: 0 15px;
          padding: 15px 0 0;

          &-img {
            height: 107px;
            width: 146px;
          }

          &-text {
            font-size: 19px;
          }
        }

        &:last-of-type {
          padding-bottom: 15px;
        }
      }

      .articles-container {
        height: auto;
      }
    }

    @each $theme in (sport, world-cup, royals, sportAU, prime, primeBlue) {
      .$theme {
        background-color: get-color($theme, main-bg);
        height: auto;
        margin: auto;
        position: relative;

        .articles-container {
          @if $theme == world-cup {
            border: 3px solid get-color($theme, main-border);
          }

          @if $theme == sport {
            border: 1px solid get-color($theme, main-border);
          }

          border-top: 0;
        }

        .header {
          background-color: get-color($theme, bg);
          color: get-color($theme, header);
          height: auto;
        }

        .view-more {
          background-color: get-color($theme, bg);
          color: get-color($theme, header);

          &:hover {
            background-color: get-color($theme, hover-bg);
            color: get-color($theme, hover-text);
            cursor: pointer;
          }
        }

        .article a {
          color: get-color($theme, link);
          text-decoration: none !important;

          &:hover {
            color: get-color($theme, link-hover);
            text-decoration: none !important;
          }
        }

        &.extra-small,
        &.small {
          .article-child {
            border-top: 1px solid get-color($theme, art-border);
          }
        }

        @if $theme == world-cup {
          &.extra-small {
            .header {
              height: 64px;

              &-text {
                width: 122px;

                &-img {
                  margin-left: -5px;
                }
              }

              &-img {
                height: 65.3px;
                width: 100%;
              }
            }
          }

          &.small {
            .header {
              height: 62px;

              &-img {
                height: 63px;
                width: 100%;
              }
            }
          }

          &.large,
          &.extra-large {
            .header {
              &-img {
                height: 68px;
                width: 100%;
              }
            }
          }

          &.medium {
            .header {
              &-img {
                height: 75px;
              }
            }
          }

          &.extra-small,
          &.small,
          &.medium {
            .header {
              &-text-world-cup {
                left: 18px;
                position: relative;
              }
            }
          }
        }

        @if $theme == sport {
          &.extra-small {
            .header {
              &-text {
                width: 170px;
              }
            }
          }

          &.small {
            .header {
              &-text {
                font-size: 17px;
                width: 170px;
              }

              &-img {
                height: 56px;
                width: auto;
              }
            }
          }

          &.medium {
            .header {
              &-text {
                width: 180px;
              }
            }
          }

          &.large,
          &.extra-large {
            .grouped-articles {
              margin-bottom: 10px;
            }
          }
        }

        @if $theme == royals {
          .view-more {
            position: relative;

            &::after {
              border: 2px solid $teak;
              content: '';
              height: 100%;
              position: absolute;
              width: 100%;
            }
          }

          .header-text-img {
            filter: invert(59%) sepia(76%) saturate(229%) hue-rotate(1deg) brightness(130%) contrast(83%);
          }

          &.extra-small {
            .header-text {
              font-size: 13px;
              width: 124px;
            }

            .article-first-child {
              position: relative;
              top: -1px;
            }
          }

          &.small {
            .article-first-child {
              position: relative;
              top: -1px;
            }

            .header-text {
              font-size: 15px;
            }
          }

          &.large {
            .grouped-articles {
              margin-bottom: 10px;
            }

            .header-text {
              font-size: 20px;
              max-width: 250px;
            }

            .article-headline {
              font-size: 17px;
            }

            .article-child-text {
              font-size: 15px;
            }

            .article-top-container {
              width: 280px;
            }
          }

          &.extra-large {
            border-radius: 14px;

            .header {
              border-radius: 14px;
              height: 70px;

              &-text {
                left: 18px;
              }

              &-img {
                height: 70px;
              }
            }

            .articles-container {
              display: grid;
              grid-template-columns: 1fr;
              padding-left: 7px;
            }

            .article-top-container {
              position: relative;
              width: auto;
              z-index: 1;
            }

            .article-child {
              padding-right: 20px;
            }

            .article-headline {
              @include text-clamp(2);

              font-size: 20px;
              line-height: 24px;
            }

            .article-first-child {
              height: 320px;

              img {
                height: 100%;
                object-fit: cover;
                width: 100%;
              }
            }

            .divider {
              border-bottom-width: 3px !important;
              bottom: 2px !important;
            }

            .view-more {
              border: 0;
              font-size: 22px;
              grid-column: 1/3;
              justify-content: flex-end;
              margin: 15px auto;
              position: relative;
              text-transform: uppercase;
              width: 880px;
              z-index: 0;

              span {
                display: flex;
              }

              .header-text-img {
                height: 23px;

                &:last-of-type {
                  margin-left: -7px;
                }
              }

              &::after {
                content: none;
              }
            }
          }

          &:not(.small, .extra-small) {
            .divider {
              border-bottom: 2px solid $teak;
              bottom: 0;
              display: block;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              width: 97%;
            }

            .view-more:hover,
            .header-text:hover {
              color: $teak;
              opacity: 0.6;
            }

            .disable-hover {
              pointer-events: none;
            }
          }
        }

        @if $theme == prime {
          .articles-container {
            &::before {
              background: #fff;
              content: '';
              display: block;
              height: 3px;
              left: 10px;
              position: absolute;
              top: -3px;
              width: calc(100% - 20px);
            }
          }

          .primeLogo {
            height: auto;
            margin-right: 30px;
            margin-top: 4px;
            width: 130px;
          }

          .header-text,
          .article-headline,
          .article-child-text {
            font-weight: 500;
          }

          &.large {
            .article-child-text {
              font-size: 14px;
              -webkit-line-clamp: 5;
            }
          }

          &.medium {
            .header-text {
              width: 320px;
            }

            .primeLogo {
              margin-right: 10px;
            }
          }

          &.small {
            .article-child-text {
              -webkit-line-clamp: 5;
            }

            .header-text {
              width: 180px;
            }

            .primeLogo {
              margin-right: 10px;
            }

            .articles-container {
              padding-top: 10px;

              &::before {
                left: 0;
                width: 100%;
              }
            }
          }

          &.extra-small {
            .article-child-text {
              -webkit-line-clamp: 5;
            }

            .header-text {
              width: 150px;
            }

            .primeLogo {
              margin-right: 10px;
              width: 100px;
            }

            .articles-container {
              padding-top: 10px;

              &::before {
                left: 0;
                width: 100%;
              }
            }
          }
        }

        @if $theme == primeBlue {
          .primeBlueLogo {
            height: auto;
            margin-right: 30px;
            margin-top: 4px;
            width: 130px;
          }

          .header-text,
          .article-headline,
          .article-child-text {
            font-weight: 500;
          }

          &.large {
            .article-child-text {
              font-size: 14px;
              -webkit-line-clamp: 5;
            }
          }

          &.medium {
            .header-text {
              width: 320px;
            }

            .primeBlueLogo {
              margin-right: 10px;
            }
          }

          &.small {
            .article-child-text {
              -webkit-line-clamp: 5;
            }

            .header-text {
              width: 180px;
            }

            .primeBlueLogo {
              margin-right: 10px;
            }

            .articles-container {
              padding-top: 10px;

              &::before {
                left: 0;
                width: 100%;
              }
            }
          }

          &.extra-small {
            .article-child-text {
              -webkit-line-clamp: 5;
            }

            .header-text {
              width: 150px;
            }

            .primeBlueLogo {
              margin-right: 10px;
              width: 100px;
            }

            .articles-container {
              padding-top: 10px;

              &::before {
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
